import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';

const Code = ({ code, language }) => (
  <Highlight
    {...defaultProps}
    theme={undefined}
    code={code}
    language={language}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => {
      return (
        <pre
          className={className}
          style={{ border: 'none', boxShadow: 'none', maxWidth: '100%' }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      );
    }}
  </Highlight>
);

export default Code;
