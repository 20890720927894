import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "message.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div
            style={{
              marginLeft: 16,
              marginRight: 16,
              paddingTop: 49,
              maxWidth: '100%',
            }}
          >
            <Img
              style={{ transition: 'margin .5s' }}
              fluid={data.placeholderImage.childImageSharp.fluid}
            />
          </div>
        );
      }}
    />
  );
};
export default Image;
