import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ name }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "action1.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div
        style={{
          marginLeft: 16,
          marginRight: 16,
          paddingTop: 19,
          maxWidth: '100%',
        }}
      >
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </div>
    )}
  />
);
export default Image;
